import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import cube from 'cube-ui'
import cookiesPlugin from '@/utils/cookies.js'
import toastPlugin from '@/utils/toast'
import httpPlugin from "@/utils/http"

Vue.config.productionTip = false
Vue.use(cube)
Vue.use(httpPlugin, { store })
Vue.use(cookiesPlugin)
Vue.use(toastPlugin)

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

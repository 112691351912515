<template>
  <div>
    <div v-if="mergeInfo" class="header-info">
      <img class="icon" :src="'https://file.18art.art/' + mergeInfo.activityPic" alt="">
      <div class="header-right">
        <span class="title">{{ mergeInfo.activityName }}</span>
        <span class="desc">{{ mergeInfo.activityInfo }}</span>
        <span v-if="startTime" class="time">开始时间：{{ formatTime(startTime) }}</span>
        <span v-if="endTime" class="time">结束时间：{{ formatTime(endTime) }}</span>
        <span v-if="countDownTips" class="count-down">{{ mergeInfo.batchSwitch ? `可批量合成，每次合成${mergeInfo.batchNum}份` : '不可批量合成' }}</span>
      </div>
    </div>
    <div v-else class="waiting-info">
      <span v-if="startTime" class="time">开始时间：{{ formatTime(startTime) }}</span>
      <span v-if="endTime" class="time">结束时间：{{ formatTime(endTime) }}</span>
      <span v-if="countDownTips" class="count-down">{{ countDownTips }}</span>
      <span v-else class="count-down">倒计时：{{ countDown }}</span>
    </div>
    <template v-if="mergeInfo">
      <cube-button class="button-item" @click="continuityMerge" :disabled="isContinuityMerge">{{ isContinuityMerge ? continuityMergeTips : '开启连续合成' }}</cube-button>
    </template>
    <cube-button v-else class="button-item" @click="rushMerge" :disabled="isRushMerge">{{ isRushMerge ? continuityMergeTips : '开启抢合成' }}</cube-button>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: "MergeDetail",
  data () {
    return {
      mergeInfo: null,
      startTime: null,
      endTime: null,
      countDownTimer: null,
      countDownTips: null,
      currentTime: null,
      isRushMerge: false,
      isContinuityMerge: false,
      continuityMergeCount: 0,
      isCompleteMerge: false
    }
  },
  computed: {
    ownList () {
      return this.mergeInfo.ownList || [];
    },
    formatTime () {
      return function (time) {
        return dayjs(Number(time)).format( 'YYYY-MM-DD HH:mm:ss');
      }
    },
    countDown () {
      if (this.currentTime) {
        const ms = this.startTime - this.currentTime;
        if (ms < 3600000) {
          return dayjs(ms).format('mm:ss');
        } else {
          return dayjs(ms).format('HH:mm:ss');
        }
      }
      return null;
    },
    continuityMergeTips () {
     return this.isCompleteMerge ? `合成完毕，总计${this.continuityMergeCount}次` : `已合成${this.continuityMergeCount}次`;
    }
  },
  mounted() {
    this.getInfo();
    const { startTime, endTime } = this.$route.query;
    this.startTime = startTime;
    this.endTime = endTime;
    this.currentTime = new Date().getTime();
    let fetchFlag = false;
    if (startTime > this.currentTime) {
      this.countDownTimer = setInterval(() => {
        this.currentTime = new Date().getTime();
        if (startTime <= this.currentTime) {
          this.countDownTips = '合成已开始';
          clearInterval(this.countDownTimer);
        } else {
          // 提前五秒开始请求合成信息
          if ((this.startTime - this.currentTime <= 5000) && !fetchFlag && this.isRushMerge) {
            fetchFlag = true;
            this.repeatGetMergeInfo();
          }
        }
      }, 1000);
    } else {
      this.countDownTips = '合成已开始';
    }
  },
  methods: {
    getInfo () {
      this.$http('/nft-web/v1.2/nft/product/getSyntheticGoodsList', {
        params: {
          activityId: this.$route.params.id
        }
      }).then(res => {
        if (res.success) {
          this.mergeInfo = res.data;
        } else {
          this.$toast(res.message, 'error');
        }
      }).catch(e => {

      })
    },
    rushMerge () {
      this.isRushMerge = true;
    },
    repeatGetMergeInfo () {
     const fetchSyntheticGoodsList = async (activityId) => {
        try {
          const res = await this.$http('/nft-web/v1.2/nft/product/getSyntheticGoodsList', {
            params: {
              activityId: activityId
            }
          });
          if (res.success) {
            return res.data;
          } else {
            return await fetchSyntheticGoodsList(activityId);
          }
        } catch (error) {
          return await fetchSyntheticGoodsList(activityId);
        }
      }
      const fetchData = async () => {
        try {
          this.mergeInfo = await fetchSyntheticGoodsList(this.$route.params.id);
          this.continuityMerge();
        } catch (error) {
          console.error(error);
        }
      }
      fetchData();
    },
    continuityMerge () {
      this.isContinuityMerge = true;
      if (this.mergeInfo.batchSwitch) {
        const ownInfo = this.ownList[0];
        const maxMergeCount = Math.floor(ownInfo.albumList[0].goodsList.length / ownInfo.selectNum);
        const mergeCounts = [];
        let remainingMergeCount = maxMergeCount;
        while (remainingMergeCount >= this.mergeInfo.batchNum) {
          mergeCounts.push(this.mergeInfo.batchNum);
          remainingMergeCount -= this.mergeInfo.batchNum;
        }
        if (remainingMergeCount > 0) {
          mergeCounts.push(remainingMergeCount);
        }
        const processMergeCounts = async (index = 0) => {
          if (index < mergeCounts.length) {
            const syntheticNum = mergeCounts[index];
            try {
              const res = await this.$http({
                method: 'post',
                url: '/nft-web/nft/product/batchSynthetic',
                data: {
                  activityId: this.$route.params.id,
                  syntheticNum: syntheticNum
                }
              });
              if (res.success) {
                // 如果请求成功，递归调用以处理下一个元素
                this.continuityMergeCount += 1;
                await processMergeCounts(index + 1);
              } else {
                console.log('Request failed, retrying for syntheticNum:', syntheticNum);
                // 如果请求不成功，不增加索引，再次尝试当前元素
                await processMergeCounts(index);
              }
            } catch (e) {
              console.error('Error occurred:', e);
              // 如果发生异常，也应该重试当前元素
              await processMergeCounts(index);
            }
          } else {
            this.isCompleteMerge = true;
          }
        };
        processMergeCounts();
      } else {
        const goodsList = this.ownList[0].albumList[0].goodsList;
        const selectNum = this.ownList[0].selectNum;
        const groupId = this.ownList[0].groupId;
        const splitGoodsList = [];
        for (let i = 0; i < goodsList.length; i += selectNum) {
          // 只有当当前索引加上分割大小仍小于原始数组长度时，才添加到新数组中
          if (i + selectNum <= goodsList.length) {
            splitGoodsList.push(goodsList.slice(i, i + selectNum));
          }
        }
        const processGoodsList = async (index = 0) => {
          if (index < splitGoodsList.length) {
            const currentGroup = splitGoodsList[index];
            try {
              const res = await this.$http({
                method: 'post',
                url: '/nft-web/nft/product/syntheticNewGoods',
                data: {
                  activityId: this.$route.params.id,
                  burnGroup: [
                    {
                      groupId, // 填写具体的groupId
                      pointType: null,
                      gIdList: currentGroup.map(subItem => subItem.gId)
                    }
                  ]
                }
              });
              if (res.success) {
                this.continuityMergeCount += 1;
                // 如果请求成功，递归调用以处理下一个元素
                await processGoodsList(index + 1);
              } else {
                // 如果请求不成功，不增加索引，再次尝试当前元素
                await processGoodsList(index);
              }
            } catch (e) {
              console.error('Error occurred:', e);
              // 如果发生异常，也应该重试当前元素
              await processGoodsList(index);
            }
          } else {
            this.isCompleteMerge = true;
          }
        };
        processGoodsList();
      }
    }
  }
}
</script>

<style scoped>
  .header-info {
    display: flex;
    align-items: center;
    margin: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  .header-info .icon {
    height: 100px;
    width: 100px;
    margin-right: 20px;
  }
  .header-info .header-right {
    display: flex;
    flex-direction: column;
  }
  .header-info .header-right .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 6px;
  }
  .header-info .header-right .desc {
    font-size: 12px;
    color: #ccc;
    margin-bottom: 6px;
  }
  .waiting-info {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  .time {
    font-size: 12px;
    line-height: 16px;
  }
  .count-down {
    font-size: 14px;
    line-height: 18px;
    color: red;
    margin-top: 4px;
  }
  .button-item {
    margin: 20px;
    width: calc(100% - 40px);
  }

</style>
<template>
  <cube-form
      :model="model"
      :schema="schema"
      :immediate-validate="false"
      :options="options"></cube-form>
</template>

<script>

export default {
  name: 'HomeView',
  data () {
    return {
      model: {
        token: ''
      },
      schema: {
        groups: [
          {
            legend: '登录',
            fields: [
              {
                type: 'input',
                modelKey: 'token',
                label: 'token',
                rules: {
                  required: true
                }
              }
            ]
          },
          {
            fields: [
              {
                type: 'button',
                label: '登录',
                events: {
                  click: this.login
                }
              }
            ]
          }
        ]
      },
      options: {
        scrollToInvalidField: true,
        layout: 'fresh'
      }
    }
  },
  mounted() {
    if (this.$store.state.eighteen.userInfo) {
      this.model.token = this.$store.state.eighteen.userInfo.token;
    }
  },
  methods: {
    login () {
      this.$loading.show();
      this.$http({
        method: 'post',
        url: '/nft-web/v1.1/nft/user/getUserInfo',
        data: {}
      }).then(res => {
        this.$loading.hide();
        if (res.success) {
          const info = res.data;
          info.token = this.model.token;
          this.$store.commit('eighteen/updateUserInfo', info);
          this.$toast('登录成功');
          this.$router.go(-1);
        } else {
          this.$toast(res.message, 'error');
        }
      }).catch(e => {
        this.$loading.hide();
        this.$toast(e.toString(), 'error');
      })
    }
  }
}
</script>

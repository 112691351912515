<template>
  <div class="home-conatiner">
    <p v-if="$store.state.eighteen.userInfo">当前登录：{{ $store.state.eighteen.userInfo.realName }}</p>
    <p v-else>未登录</p>
    <cube-button class="button-item" @click="manageLogin">登录管理</cube-button>
    <cube-button class="button-item" @click="myStore">我的仓库</cube-button>
    <cube-button class="button-item" @click="merge">合成</cube-button>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data () {
    return {
    }
  },
  mounted() {
    if (!this.$store.state.eighteen.userInfo) return;
    this.$http({
      method: 'post',
      url: '/nft-web/v1.1/nft/user/getUserInfo',
      data: {}
    }).then(res => {
      this.$loading.hide();
      if (res.success) {
        const info = res.data;
        info.token = this.$store.state.eighteen.userInfo.token;
        this.$store.commit('eighteen/updateUserInfo', info);
        this.$toast('登录成功');
      } else {
        this.$toast(res.message, 'error');
        this.$store.commit('eighteen/clearUserInfo');
      }
    }).catch(e => {
      this.$toast(e.toString(), 'error');
      this.$store.commit('eighteen/clearUserInfo');
    })
  },
  methods: {
    manageLogin () {
      this.$router.push({
        path: '/eighteen/login'
      })
    },
    myStore () {
      if (!this.$store.state.eighteen.userInfo) {
        this.$router.push({
          path: '/eighteen/login'
        })
        return;
      }
      this.$router.push({
        path: '/eighteen/my-store'
      })
    },
    merge () {
      if (!this.$store.state.eighteen.userInfo) {
        this.$router.push({
          path: '/eighteen/login'
        })
        return;
      }
      this.$router.push({
        path: '/eighteen/merge'
      })
    }
  }
}
</script>
<style scoped>
  .home-conatiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 44px 20px 0 20px;
  }
  .button-item {
    margin-top: 20px;
  }
</style>

<template>
  <div class="store-container">
    <cube-scroll ref="scroll" :options="options" :data="list" @pulling-down="onPullingDown" @pulling-up="onPullingUp">
      <ul>
        <li v-for="item in list" :key="item.albumId" class="album-item" @click="albumDetail(item.albumId)">
          <img :src="'https://file.18art.art/' + item.thumbPic" alt="">
          <span>{{ item.albumName }}</span>
        </li>
      </ul>
    </cube-scroll>
  </div>
</template>

<script>
export default {
  name: "MyStore",
  data () {
    return {
      currentPage: 1,
      list: [],
      options: {
        pullDownRefresh: {
          threshold: 60,
          txt: '刷新成功'
        },
        pullUpLoad: true
      }
    }
  },
  mounted() {
    if (!this.$store.state.eighteen.userInfo) {
      this.$toast('请登录', 'error');
      return;
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      const { userInfo } = this.$store.state.eighteen;
      this.$http({
        method: 'post',
        url: '/nft-web/v1.4/nft/product/getCollectionListByUidV4',
        data: {
          uid: userInfo.userId,
          page: this.currentPage,
          pageSize: 20,
          type: 0,
          name: null,
          nftType: 0
        }
      }).then(res => {
        if (res.success) {
          this.list = this.currentPage === 1 ? res.data.list : this.list.concat(res.data.list);
        } else {
          this.$toast(res.message, 'error');
        }
      }).catch(error => {
        this.$toast(error.toString(), 'error');
      });
    },
    onPullingDown() {
      this.currentPage = 1;
      this.fetchData();
    },
    onPullingUp() {
      this.currentPage++;
      this.fetchData();
    },
    albumDetail (albumId) {
      this.$router.push({
        path: `/eighteen/album/${albumId}`
      })
    }
  }
}
</script>

<style scoped>
  .store-container {
    height: 100vh;
  }
  .album-item {
    display: flex;
    align-items: center;
    padding: 5px 20px;
  }
  .album-item img {
    height: 28px;
    width: 28px;
    margin-right: 10px;
  }
</style>
<template>
  <div>
    ！E0B5I1o2IZk30NKV！
  </div>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>

</style>
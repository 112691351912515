import Home from '@/views/eighteen/Home';
import Login from '@/views/eighteen/Login';
import MyStore from '@/views/eighteen/MyStore';
import AlbumDetail from '@/views/eighteen/AlbumDetail';
import MergeList from '@/views/eighteen/MergeList';
import MergeDetail from '@/views/eighteen/MergeDetail';

export default [
  {
    path: '/eighteen',
    name: 'home',
    component: Home
  },
  {
    path: '/eighteen/login',
    name: 'login',
    component: Login
  },
  {
    path: '/eighteen/my-store',
    name: 'myStore',
    component: MyStore
  },
  {
    path: '/eighteen/album/:id',
    name: 'albumDetail',
    component: AlbumDetail,
    props: true
  },
  {
    path: '/eighteen/merge',
    name: 'mergeList',
    component: MergeList,
    props: true
  },
  {
    path: '/eighteen/merge/:id',
    name: 'mergeDetail',
    component: MergeDetail,
    props: true
  }
];

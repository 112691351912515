// toastPlugin.js
import { Toast } from 'cube-ui';

export default {
  install(Vue) {
    const Loading = Vue.extend({
      methods: {
        show() {
          this.toast = Toast.$create({
            txt: 'Loading...',
            time: 0,
            mask: true
          }).show();
        },
        hide() {
          if (this.toast) {
            this.toast.hide();
          }
        }
      }
    });

    Vue.prototype.$toast = function(message, type = 'txt', time = 2000) {
      Toast.$create({
        txt: message,
        type,
        time
      }).show();
    };

    Vue.prototype.$loading = new Loading();
  }
};

<template>
  <div class="merge-conatienr">
    <ul v-if="activityList.length">
      <li v-for="(item, index) in activityList" :key="item.id" class="activity-item" @click="activityDetail(item)">
        <span class="title">{{ index + 1 }}.{{ item.activityName }}</span>
        <span class="desc">{{ item.activityInfo }}</span>
      </li>
    </ul>
    <span v-else>暂无合成</span>
  </div>
</template>

<script>
export default {
  name: "MergeList",
  data () {
    return {
      activityList: []
    }
  },
  mounted() {
    if (!this.$store.state.eighteen.userInfo) {
      this.$toast('请登录', 'error');
      return;
    }
    this.fetchData();
  },
  methods: {
    fetchData () {
      this.$http('/nft-web/nft/home/activity', {
        params: {
          id: 28
        }
      }).then(res => {
        this.activityList = res.data.activityList.filter(item => item.activityStatus !== 2);
      }).catch(e => {

      })
    },
    activityDetail (info) {
      const { id, startTime, endTime } = info;
      this.$router.push({
        path: `/eighteen/merge/${id}`,
        query: {
          startTime,
          endTime
        }
      })
    }
  }
}
</script>

<style scoped>
  .merge-conatienr {
    height: 100vh;
  }
  .activity-item {
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
  .activity-item .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .activity-item .desc {
    font-size: 12px;
    color: #ccc;
  }
</style>
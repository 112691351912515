<template>
  <div>
    <div class="header-wrapper">
      <img v-if="albumInfo.gUrls" :src="'https://file.18art.art/' + albumInfo.gUrls[0].url" alt="">
      <span>{{ albumInfo.albumName }}</span>
    </div>
    <div>
      <span class="cell-header">实时价格</span>
      <div>
        <span v-for="item in priceList">￥{{ item.priceCny }}</span>
      </div>
    </div>
    <div>
      <span class="cell-header">我的藏品</span>
      <div class="my-album-container">
        <div class="my-album-item" v-for="item in myAlbums">
          <span class="price">￥{{ item.priceCny }}</span>
          <span class="num">#{{ item.gNum }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlbumDetail",
  data () {
    return {
      albumInfo: {},
      myAlbums: [],
      priceList: []
    }
  },
  mounted() {
    if (!this.$store.state.eighteen.userInfo) {
      this.$toast('请登录', 'error');
      return;
    }
    this.getInfo();
  },
  methods: {
    getInfo () {
      Promise.all([this.$http('/nft-web/v1.2/nft/album/info', {
        params: {
          albumId: this.$route.params.id
        }
      }), this.$http({
        method: 'post',
        url: '/nft-web/v1.4/nft/product/getGoodsListByAlbumIdV4',
        data: {
          uid: this.$store.state.eighteen.userInfo.userId,
          page: 1,
          pageSize: 50,
          type: 0,
          dateRange: null,
          albumId: this.$route.params.id
        }
      })]).then(([albumInfoRes, myAlbumsRes]) => {
        if (!albumInfoRes.success || !myAlbumsRes.success) {
          this.$toast(albumInfoRes.message + myAlbumsRes.message, 'error');
        } else{
          this.albumInfo = albumInfoRes.data;
          this.myAlbums = myAlbumsRes.data.list;
          this.fetchPriceRepeatedly();
        }
      }).catch(error => {
        this.$toast(error.toString(), 'error');
      });
    },
    async fetchPriceRepeatedly() {
      // 定义请求函数
      const fetchPrice = async () => {
        try {
          const res = await this.$http('/nft-web/v1.2/nft/product/getProductListByAlbumId', {
            params: {
              page: 1,
              pageSize: 50,
              order: 1,
              albumId: this.$route.params.id,
              onSale: 1
            }
          });
          if (res.success) {
            this.priceList = res.data.list;
          }
        } catch (error) {

        }
      };

      // 递归函数调用
      const repeatFetch = async () => {
        await fetchPrice(); // 等待请求完成
        setTimeout(repeatFetch, 5000); // 等待一秒后再次调用自己
      };

      repeatFetch(); // 开始循环请求
    }
  }
}
</script>

<style scoped>
  .header-wrapper {
    padding: 14px;
    display: flex;
    align-items: flex-end;
  }
  .header-wrapper img {
    width: 50px;
    margin-right: 10px;
  }
  .header-wrapper span {
    font-size: 20px;
    font-weight: bold;
  }
  .my-album-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 10px;
  }
  .my-album-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 4px;
    padding: 4px;
    min-width: 30px;
  }
  .my-album-item .price {
    font-size: 12px;
    color: #cccccc;
  }
  .my-album-item .num {
    font-size: 14px;
    font-weight: bold;
  }
  .cell-header {
    font-size: 16px;
    font-weight: bold;
    padding: 14px;
  }
</style>
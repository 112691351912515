// store/modules/eighteen.js

const state = {
  userInfo: null, // 用户信息的初始状态
};

const mutations = {
  // 更新用户信息
  updateUserInfo(state, userInfo) {
    state.userInfo = userInfo;
  },
  // 清除用户信息
  clearUserInfo(state) {
    state.userInfo = null;
  }
};

const actions = {
  // 用户登出
  logout({ commit }) {
    commit('clearUserInfo'); // 清除用户信息
  },
  // 更新用户信息
  updateUserInfo({ commit }, userInfo) {
    commit('updateUserInfo', userInfo); // 更新用户信息
  }
};

export default {
  namespaced: true, // 使用命名空间
  state,
  mutations,
  actions
};

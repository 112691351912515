// src/store/index.js
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import eighteen from './modules/eighteen'; // 引入eighteen模块

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    eighteen // 注册eighteen模块
  },
  plugins: [createPersistedState({
    paths: ['eighteen']
  })]
});

// axiosPlugin.js
import axios from 'axios';

const httpPlugin = {
  install(Vue, { store }) {
    Vue.prototype.$http = axios.create({
      timeout: 10000
    });

    // 请求拦截器
    Vue.prototype.$http.interceptors.request.use(config => {
      // 18art
      if (config.url.startsWith('/nft-web')) {
        // 修改 URL 以包含完整的前缀
        config.url = `https://api-app.18art.art${config.url}`;
        const userInfo = store.state.eighteen.userInfo;
        config.headers['User-Token'] = userInfo.token;
        config.headers['X-Cloudbase-Phone'] = userInfo.mobile;
        config.headers['Platform-Type'] = 'h5_browser';
      }
      return config;
    });

    // 响应拦截器
    Vue.prototype.$http.interceptors.response.use(
      response => {
        // 对响应数据做点什么
        return response.data;
      },
      error => {
        // 对响应错误做点什么
        return Promise.reject(error);
      }
    );
  }
};

export default httpPlugin;
